/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.btn-open').click(function(){
          $(this).toggleClass('btn-close');
          $('body').toggleClass('menu-open');
        });
        //
        AOS.init({
          easing: 'ease-in-out-sine'
        });
      },
      finalize: function() {
        /*
        * Replace all SVG images with inline SVG
        */
        jQuery('img.svg').each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
        // JavaScript to be fired on all pages, after page specific JS is fired
        // scroll end extension:
        $.fn.scrollEnd = function(callback, timeout) {
          $(this).scroll(function(){
            var $this = $(this);
            if ($this.data('scrollTimeout')) {
              clearTimeout($this.data('scrollTimeout'));
            }
            $this.data('scrollTimeout', setTimeout(callback,timeout));
          });
        };

        // set a scroll timeout to prevent addClass / removeClass flicker
        $(window).scrollEnd(function(){
            // do stuff
            var sticky = $('#main_nav');
            //
            var split_url = window.location.pathname.split('/');
            var page_name = split_url[1];
            //
            if ($(document).scrollTop() > 0) {
                $(".navbar-sticky-top").removeClass('sticky-navbar-header-transparent').addClass('sticky-navbar-header-black');
                //
                if (page_name.length === 0)
                {
                //  $('.donateBtn').show().css('display', 'flex');
                 // $('.btn-donate').hide();
                }
            } else {
                $(".navbar-sticky-top").removeClass('sticky-navbar-header-black').addClass('sticky-navbar-header-transparent');
                //
                if (page_name.length === 0)
                {
                 // $('.donateBtn').hide();
                 // $('.btn-donate').show();
                }
            }
        }, 5);

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.flexslider').flexslider({
          // directionNav: false,
          controlNav: false,
          animationLoop: false,
          // smoothHeight: true
          });
          var currentSection = 'section-1';
         function checkSection(){
           $('section').each(function() {
              var section = $(this);
              var position = section.position().top - $(window).scrollTop();

              if (position <= 500) {
                  section.addClass('selected');
                  if(section.attr('id') !== currentSection){
                    $('.dotnav').removeClass('whiteNav');
                    $('.dotnav').removeClass('blueBg');
                    currentSection = section.attr('id');
                    $('.dotnav li').removeClass('active');
                    $('#'+currentSection+'-nav').addClass('active');
                    if($('#'+currentSection).hasClass('bg-white')){
                      $('.dotnav').addClass('whiteNav');
                    }
                    if($('#'+currentSection).hasClass('bg-blue')){
                      $('.dotnav').addClass('blueBg');
                    }
                  }

                  //console.log(currentSection);
              } else {
                  section.removeClass('selected');
              }
          });
        }
        $(window).bind('scroll', function() {
          checkSection();
        });
      },
      finalize: function() {
        //  JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'donate': {
      init: function(){
        $('.flexslider').flexslider({
         // directionNav: false

          });
      }

    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      //  remove fade-out class on page load - set in _glboal.scss body
      $('body').removeClass('fade-out');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
